

export const CHART_TYPES = [
    { value: "bar", label: "Bar" },
    { value: "line", label: "Line" },
];

export const SERIES_DATA_OPTIONS = [
    { value: "pv", label: "PV Data" },
    {
        value: "load",
        label: "Load Data",
    },
    { value: "daily", label: "Daily Report" },
];

export const KPI_OPTIONS = [
    { label: "Cost", value: "cost", unit: '€' },
    { label: "Saving", value: "saving", unit: '€' },
    { label: "Consumption (Load)", value: "load", unit: 'kW'},
    { label: "Production (PV)", value: "pv_production", unit: 'kW'},
    { label: "Grid", value: "grid" , unit: 'kW'},
    { label: "Grid (Purchase)", value: "grid-negative", unit: 'kW' },
    { label: "Grid (Feed-in)", value: "grid-positive", unit: 'kW' },
    { label: "Battery", value: "battery", unit: 'kW' },
    { label: "Battery (Charge)", value: "battery-positive", unit: 'kW' },
    { label: "Battery (Discharge)", value: "battery-negative", unit: 'kW' }
]

export const TIME_PERIOD_OPTIONS = [
    { "label": "Yesterday", "value": "yesterday" },
    { "label": "This Week", "value": "this_week" },
    { "label": "This Month", "value": "this_month" },
    { "label": "This Year", "value": "this_year" },
]