import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import _ from "lodash";

import CustomSelectOptions from "../../formComponents/CustomSelectOptions";
import {
    KPI_OPTIONS,
    TIME_PERIOD_OPTIONS,
} from "../../../constants/widgetsTypes";
import LoadingButton from "../../LoadingButton";
import SelectTable from "../../formComponents/SelectTable";
import TextArea from "antd/es/input/TextArea";

type EditCardWidgetComponentProps = {
    onSubmit: any;
    initialValue?: any;
    loading: boolean;
};

const EditCardWidgetComponent: React.FC<EditCardWidgetComponentProps> = ({
    onSubmit,
    loading,
    initialValue,
}) => {
    const [form] = Form.useForm();
    const [selectedSystem, setSelectedSystem] = useState<string | null>(null);
    const [selectedSite, setSelectedSite] = useState<string | null>(null);
    const [devicesFilter, setDevicesFilter] = useState<any>();

    useEffect(() => {
        if (initialValue?.systemId) {
            setSelectedSystem(initialValue.systemId);
        } else if (initialValue?.siteId) {
            setSelectedSite(initialValue.siteId);
        }
    }, [initialValue]);

    const handleSystemChange = (value: any) => {
        form.setFieldsValue({ systemId: value, siteId: null, deviceId: null });
        setSelectedSystem(value);
        setSelectedSite(null);
    };

    const handleSiteChange = (value: any) => {
        form.setFieldsValue({ siteId: value, systemId: null, deviceId: null });
        setSelectedSystem(null);
        setSelectedSite(value);
    };

    const handleDeviceChange = (value: any) => {
        form.setFieldsValue({ deviceId: value });
    };

    useEffect(() => {
        if (selectedSystem) {
            setDevicesFilter([
                {
                    table: "system_id",
                    value: selectedSystem,
                },
            ]);
        } else if (selectedSite) {
            setDevicesFilter([
                {
                    table: "location_id",
                    value: selectedSite,
                },
            ]);
        }
    }, [selectedSystem, selectedSite]);

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={(values: any) => onSubmit(values, form)}
            initialValues={initialValue}
        >
            <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "Please input the title!" }]}
            >
                <Input placeholder="Enter title" />
            </Form.Item>
            <div className="flex flex-row gap-4 mb-4">
                <Form.Item label="System" name="systemId" className="w-full">
                    <SelectTable
                        table="systems"
                        placeholder="Select System"
                        onChange={handleSystemChange}
                        value={form.getFieldValue("system_id")}
                    />
                </Form.Item>
                <Form.Item label="Site" name="siteId" className="w-full">
                    <SelectTable
                        table="sites"
                        placeholder="Select Site"
                        onChange={handleSiteChange}
                        value={form.getFieldValue("site_id")}
                    />
                </Form.Item>
            </div>
            {(selectedSite || selectedSystem) && (
                <Form.Item label="Device" name="deviceId" className="w-full">
                    <SelectTable
                        table="system_devices"
                        placeholder="Select Device"
                        onChange={(val: any, options: any) =>
                            handleDeviceChange(val)
                        }
                        value={form.getFieldValue("device_id")}
                        filter={devicesFilter}
                        key={
                            devicesFilter
                                ? devicesFilter[0]?.value
                                : "no-filter"
                        } // Force re-render by changing the key
                    />
                </Form.Item>
            )}
            <div className="flex flex-row gap-4 mb-4">
                <Form.Item
                    label="KPI"
                    name="kpi"
                    rules={[
                        {
                            required: true,
                            message: "Please select the KPI!",
                        },
                    ]}
                    className="w-full"
                >
                    <CustomSelectOptions
                        options={KPI_OPTIONS}
                        onChange={(val: any) => form.setFieldValue("kpi", val)}
                    />
                </Form.Item>
                <Form.Item
                    label="Time Period"
                    name="timePeriod"
                    rules={[
                        {
                            required: true,
                            message: "Please select the time period!",
                        },
                    ]}
                    className="w-full"
                >
                    <CustomSelectOptions
                        options={TIME_PERIOD_OPTIONS}
                        onChange={(val: any) =>
                            form.setFieldValue("timePeriod", val)
                        }
                    />
                </Form.Item>
            </div>

            <Form.Item label="Description" name="description">
                <TextArea rows={4} placeholder="Enter Description" />
            </Form.Item>
            <Form.Item>
                <LoadingButton
                    loading={loading}
                    title={_.isEmpty(initialValue) ? "Save" : "Update"}
                />
            </Form.Item>
        </Form>
    );
};

export default EditCardWidgetComponent;
