import React, { useEffect, useState } from "react";
import { client2 } from "../../operationalRequest";
import { KPI_OPTIONS } from "../../constants/widgetsTypes";

interface IncrementColors {
    positive: string;
    negative: string;
}

interface CardWidgetProps {
    title?: string;
    value?: number | null;
    unit?: string;
    description?: string;
    increment?: number | null;
    incrementSuffix?: string;
    incrementColors?: IncrementColors;
    cardType?: string;
    filter: CardFilterProps;
}

interface CardFilterProps {
    kpi: string;
    timePeriod: string;
    systemId?: number;
    siteId?: number;
    deviceId?: number;
}

const CardWidget: React.FC<CardWidgetProps> = ({
    title,
    filter,
    incrementSuffix = "%",
    description,
    incrementColors = { positive: "bg-green-200", negative: "bg-red-200" },
}) => {
    const [displayValue, setDisplayValue] = useState<number | null>();
    const [displayPreviousValue, setDisplayPreviousValue] = useState<
        number | null
    >();
    const [displayUnit, setDisplayUnit] = useState<string | null>();
    const [displayIncrement, setDisplayIncrement] = useState<number | null>(
        null,
    );

    // FECTCH API TO GET VALUE OF REQUIRED SELECT TYPE
    const fetchData = async () => {
        try {
            if (filter.kpi || filter.timePeriod) {
                const params = new URLSearchParams({
                    kpi: filter.kpi,
                    timePeriod: filter.timePeriod,
                    ...(filter.systemId && {
                        systemId: filter.systemId.toString(),
                    }),
                    ...(filter.siteId && { siteId: filter.siteId.toString() }),
                    ...(filter.deviceId && {
                        deviceId: filter.deviceId.toString(),
                    }),
                });

                const response = await client2.get(
                    `api/widgets/reports?${params.toString()}`,
                );
                const data = response?.data?.data;

                const currentUnit = KPI_OPTIONS.find(
                    (option: any) => option.value === filter.kpi,
                )?.unit;

                setDisplayValue(data?.currentTotal);
                setDisplayUnit(currentUnit || "");
                setDisplayPreviousValue(data?.previousTotal);
                setDisplayIncrement(data?.percentageDifference || 0);
            }
        } catch (e) {
            console.error("Error fetching data:", e);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="h-full w-full border-0 shadow-none">
            <span>{title}</span>
            <div className="flex space-x-1 mt-2">
                <h5 className="font-semibold text-lg">
                    {displayValue !== null && displayValue !== undefined
                        ? displayValue.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                          })
                        : 0}{" "}
                    {displayUnit}
                </h5>
                {displayIncrement !== null &&
                    displayIncrement !== undefined && (
                        <small
                            className={`${
                                displayIncrement < 0
                                    ? incrementColors.negative
                                    : incrementColors.positive
                            } px-1 my-auto`}
                        >
                            {displayIncrement.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                            {incrementSuffix}
                        </small>
                    )}
            </div>
            <div className="flex text-sm">
                {displayPreviousValue !== null &&
                displayPreviousValue !== undefined ? (
                    <>
                        <small>previously: </small>
                        <small>
                            {displayPreviousValue.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}{" "}
                            {displayUnit}
                        </small>
                    </>
                ) : null}
            </div>

            <small>{description}</small>
        </div>
    );
};

export default CardWidget;
