import React, { useState } from "react";
import { Modal } from "antd";
import toastr from "toastr";
import { client2 } from "../../../operationalRequest";
import AddButton from "../../../components/button/AddButton";
import EditTrackProcessComponent from "./EditTrackProcessComponent";


type AddTrackProcessProps = {
    mutate?: any;
};

const AddTrackProcess: React.FC<AddTrackProcessProps> = ({ mutate }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const onSubmit = async (values: any, form: any) => {
        try {
            if (loading) return;
            const response = await client2.post('api/copy-instance', { ...values });
            mutate({ data: response.data });
            setOpen(false);
            toastr.success(response?.data.message);
            form.resetFields();
        } catch (e: any) {
            console.log(e);
        }
        setLoading((curr: any) => false);
    };

    return (
        <>
            <AddButton title="Copy Instance" setOpen={setOpen} />
            <Modal
                title={"Copy Instance"}
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={800}
                okButtonProps={{ style: { display: "none" } }}
            >
                <EditTrackProcessComponent loading={loading} onSubmit={onSubmit} />
            </Modal>
        </>
    );
};

export default AddTrackProcess;
