import _ from "lodash";

const columns = (setMutate: (mutate: any) => void) => [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: true,
        filter: true,
        filterBy: {
            type: 'text'
        }

    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: false,
    },
    {
        title: "Request",
        dataIndex: "request",
        key: "request",
        sorter: false,
        render: (row: any, record: any) => {
            return (
                <ul>
                    {Object.entries(record.requestData || []).map(([key, value]) => (
                        <li key={key} className="mb-2">
                            <small><strong>{_.startCase(key)}:</strong> {value as any}</small>
                        </li>
                    ))}
                </ul>
            )
        },
    },
    // {
    //     title: "Error",
    //     dataIndex: "message",
    //     key: "message",
    //     sorter: false,
    //     render: (row: any, record: any) => {
    //         return (
    //             <ul>
    //                 {Object.entries(record.message || []).map(([key, value]) => (
    //                     <li key={key} className="mb-2">
    //                         <strong>{_.startCase(key)}:</strong> {value as any}
    //                     </li>
    //                 ))}
    //             </ul>
    //         )
    //     },
    // },


];

export default columns;
