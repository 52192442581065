import React, { useState } from 'react'
import Layout from '../../../components/layouts/Layout'
import OperationalGrid from '../../../components/datatable/OperationalGrid'
import columns from './TrackProcessColumn';
import { READ_COMPANY } from '../../../utils/permission';
import PermissionCheck from '../../../components/authorizeComponent/PermissionCheck';
import AddTrackProcess from './AddTrackProcess';

const TrackProcess = () => {
    const [mutate, setMutate] = useState({} as any);

  return (
    <Layout title="Track Process Log">
      <OperationalGrid
        columns={columns(setMutate)}
        url={'api/track-process'}
        context="track_process_logs"
        addBody={
          <PermissionCheck permission={READ_COMPANY}>
              <AddTrackProcess mutate={setMutate} />
          </PermissionCheck>
        }
        mutate={mutate}
        paginationOption={true}
      />
    </Layout>
  )
}

export default TrackProcess