import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import { useSelector } from "react-redux";
import LoadingButton from "../../../components/LoadingButton";
import SelectTable from "../../../components/formComponents/SelectTable";
import TextField from "../../../components/formComponents/TextField";

type EditTrackProcessComponentProps = {
    onSubmit: any;
    initialValue?: any;
    loading: boolean;
};

const EditTrackProcessComponent: React.FC<EditTrackProcessComponentProps> = ({
    onSubmit,
    initialValue,
    loading,
}) => {
    const [form] = Form.useForm();
    const { role } = useSelector((state: any) => state.role);

    return (
        <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            initialValues={initialValue}
            onFinish={(values: any) => onSubmit(values, form)}
            autoComplete="off"
            layout="vertical"
        >

            <Form.Item
                label="Copy Of"
                name="company_id"
                rules={[{ required: true, message: "Please input name!" }]}
            >
                <SelectTable table='companies' placeholder='select company' />
            </Form.Item>

            <TextField name={'instance_firstname'} rules={[{ required: true, message: "Please input first name!" }]} label='First Name' />
            <TextField name={'instance_lastname'} rules={[{ required: true, message: "Please input last name!" }]} label='Last Name' />
            <TextField name={'company_name'} rules={[{ required: true, message: "Please input company name!" }]} label='Company Name' />
            <TextField name={'instance_email'} rules={[{ required: true, message: "Please input email!" }]} label='Copy To Email' />

            <Form.Item>
                <LoadingButton
                    loading={loading}
                    title={"Proceed"}
                />
            </Form.Item>
        </Form>

    );
};

export default EditTrackProcessComponent;
